import axios from "axios";

const mailerAPI = 'https://m.myapp2go.de/services/aw_mailer_signup.php';

const Myapp2goMailerService = {
    service: {
        mail: async(pBody) => {
            try {
                const response = await axios.post(mailerAPI, pBody, {
                    headers: {

                        'Content-Type': 'multipart/form-data'
                    }
                });

                return (response.data);

            } catch (e) {
                console.log(e)
            }
        }
    }
}

export default Myapp2goMailerService;