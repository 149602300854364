<template>
  <nav class="sticky-top" id="vue">

    <b-navbar class="aw-bg" toggleable="lg" type="light">
      <b-navbar-brand href="#">
        <img src="https://m.myapp2go.de/customerpics/10062_16i9bhjujkbvpj2fqqwp_openai.png" alt="logo" width="30"
          height="30" class="d-inline-block mr-2">AI Vision
      </b-navbar-brand>
      <b-navbar-toggle v-if="$route.name == 'home'" target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" v-model="showCollapse" is-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav v-if="$route.name == 'home'" class="ml-auto">

          <div class="menu-item"><router-link to="/home">Home</router-link></div>

          <div class="menu-item"><router-link to="/test">Billing</router-link></div>

          <div class="menu-item"><router-link to="/login">Docs</router-link></div>

          <b-nav-form>
            <!--           <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
              <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button> -->

            <b-nav-item v-on:click="logout()">Logout</b-nav-item>

          </b-nav-form>

        </b-navbar-nav>


      </b-collapse>

    </b-navbar>

  </nav>

</template>

<script>

export default {
  data() {
    return {
      showCollapse: false,
      authenticated: false
    }
  },
  watch: {
    '$route'() {
      this.showCollapse = false
    }
  },
  name: 'navbar',
  components: {

  },

  methods: {

    logout() {

      this.$emit('logout');

      this.authenticated = false;
      this.$store.commit("setAuthStatus", false);

      this.$router.push({ name: "login" });
    },
    toggleSidebar() {
      this.navOpen = !this.navOpen;
    },
  }
}
</script>
<style>
nav {

  display: flex;
  align-items: center;
  justify-content: center;
}


nav .menu-item {
  color: #878080;
  padding: 15px 30px;
  position: relative;
  text-align: center;
  border-bottom: 1px solid transparent;
  display: flex;
  transition: 0.4s;
}

nav .menu-item.active,
nav .menu-item:hover {
  background-color: #d4d0d077;
  border-bottom-color: #fbf7f7;
}

nav .menu-item a {
  color: inherit;
  text-decoration: none;
}

.header {
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>