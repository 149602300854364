import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import UUID from "vue-uuid";
import router from './router';
import Clipboard from 'v-clipboard';

import './app.scss'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
    // Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(UUID);

Vue.use(Clipboard);

Vue.config.productionTip = false;

Vue.prototype.$scrollTop = function() {

    // window.scrollTo(0, 0);

    window.scrollTo({ top: 0, behavior: 'smooth' });
}

new Vue({
    store,
    router,
    render: (h) => h(App),
}).$mount("#app");