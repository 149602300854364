import { render, staticRenderFns } from "./MultiForm.vue?vue&type=template&id=8e0545f4&"
import script from "./MultiForm.vue?vue&type=script&lang=js&"
export * from "./MultiForm.vue?vue&type=script&lang=js&"
import style0 from "./MultiForm.vue?vue&type=style&index=0&id=8e0545f4&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports