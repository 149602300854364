<template>
  <div style="margin: 10px 10px 10px 10px">
    <div class="container-fluid mt-3">
      <div class="row pt-2">
        <div class="card mb-4" style="width: 100%">
          <div class="card-body">
            <div class="row">
              <div class="col-5">
                <div class="icon-big">
                  <img v-if="avatar" :src="avatar" alt="avatar" width="60" height="60" class="mr-3" />
                </div>
                <!-- 
                <b-alert show variant="primary">Token geasmt: 10.000 </b-alert>
                <b-alert show variant="primary">Token übrig: 1234 </b-alert>
 -->
              </div>
              <div class="col-7">
                <div class="numbers">
                  <div>
                    <p class="card-category">you are logged in</p>
                    <h6>{{ user }}</h6>
                  </div>
                </div>
              </div>
            </div>

            <!--   <p class="text-muted">logged in USER</p>

          <hr />

          <p class="card-text">
            <small class="text-muted">Last updated 3 mins ago</small>
          </p> -->
            <!--  <b-button size="sm" v-on:click="getDocuments()" variant="primary">refresh</b-button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserBox",
  props: {
    user: String,
    avatar: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
