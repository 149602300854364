/* eslint-disable */
<template>
  <div :style="bgProps" class="content">

    <div class="center pt-3">

      <div class="dist_30"></div>

      <b-card no-body class="mb-2 box-shadow" align="left">
        <div class="card-body">
          <h4 class="card-title">Create your account</h4>

          <b-form v-if="show">
            <b-form-group id="input-group-1" label="Email address" label-for="input-1"
              description="never share your email with anyone else">
              <b-form-input id="input-1" v-model="form.email" type="email" placeholder="Enter email" required>
              </b-form-input>
            </b-form-group>

            <b-form-group id="input-group-1" label="User name" label-for="input-4" description="your username">
              <b-form-input id="input-4" v-model="form.username" type="text" placeholder="Enter User name" required>
              </b-form-input>
            </b-form-group>


            <b-form-group id="input-group-2" label="Password" label-for="input-2" description="at least 8 character">
              <b-form-input type="password" id="input-2" v-model="form.password" placeholder="Enter password" required>
              </b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" label="Password repeat" label-for="input-3"
              description="at least 8 character">
              <b-form-input type="password" id="input-3" v-model="form.password_repeat" placeholder="Enter password"
                required>
              </b-form-input>
            </b-form-group>

            <b-button v-on:click="doRegister()" class="btn btn-block" variant="outline-primary">
              <b-icon icon="person-plus"></b-icon>
              Sign Up</b-button>
            <div class="dist_10"></div>
            <b-button size="sm" type="reset" variant="outline-warning">Reset</b-button>
          </b-form>

          <hr />

          <small class="card-title mb-2">already have an account? </small>
          <b-button class="btn btn-block" variant="outline-info" v-on:click="gotoLogin()">
            <b-icon icon="person-fill"></b-icon> Login
          </b-button>


        </div>
      </b-card>

    </div>
  </div>
</template>

<script>

import { Client, Account } from 'appwrite';

import { uuid } from "vue-uuid";

import Myapp2goMailerService from "@/services/Myapp2goMailerService";

// PROD
var DBCollectionArray = new Map();
DBCollectionArray.set('users', '63446cd877f69df94aca');

const endpoint = 'https://bf2a562.online-server.cloud/v1';
const project = '63446724a6d27e696227';

const client = new Client()
  .setEndpoint(endpoint) // Replace with your endpoint
  .setProject(project); // Replace with your API Key


/* const mailer = async (res) => {

  var JSONFormData = new FormData();

  JSONFormData.append("to", res.email);
  JSONFormData.append("id", res.$id);
  JSONFormData.append("name", res.name);

  try {

    let response = await Myapp2goMailerService.service.mail(JSONFormData);

    let checkResponse = response.items;

    console.log("mailer result : ",  checkResponse );

    if (checkResponse === "OK") {

      console.log("write mailer result in DB: ");

      // $options.methods.makeToast("success", "", "write mailer result in DB");
      
    }

  } catch (error) {

    console.log(error);

  }

}; */

export default {
  name: "Login",
  beforeMount() {
    this.authenticated = this.$store.state.authenticated;
  },
  data() {
    return {

      form: {},

      show: true,

      mailObj: null,

      bgProps: {

        backgroundImage: `url(${require('@/assets/bg_data_extraction.png')})`

      }


    };
  },
  /*  mixins: [requestsMixin], */

  methods: {

    doRegister: function () {

      if (this.form.password != this.form.password_repeat) {

        this.makeToast("danger", this.form.email, "passwords not equal!");

        return;

      }

      const account = new Account(client);

      this.uuid = uuid.v4();

      account.create(
        this.uuid,
        this.form.email,
        this.form.password,
        this.form.username
      ).then(response => {

        console.log(response);

        this.makeToast("success", response.$id, "your registration data were saved successfully");

        this.sendMail(response);

        //mailer(response);


      }, error => {
        console.log(error);
        this.makeToast("danger", error.message, "registration failed");
      });

    },

    async sendMail(res) {

      var JSONFormData = new FormData();

      JSONFormData.append("to", res.email);
      JSONFormData.append("id", res.$id);
      JSONFormData.append("name", res.name);


      try {

        let response = await Myapp2goMailerService.service.mail(JSONFormData);

        let checkResponse = response.items;

        console.log("mailer result : ", checkResponse);

        if (checkResponse === "OK") {

          console.log("write mailer result in DB: ");

          this.makeToast("success", "", "write mailer result in DB");

        }

      } catch (error) {

        console.log(error);

      }

    },

    gotoLogin: function () {

      this.$router.push({ name: "login" });

    },

    makeToast(variant = null, pMessage, pText) {
      this.$bvToast.toast(`ID: ${pMessage}`, {
        title: pText,
        toaster: "b-toaster-bottom-full",
        autoHideDelay: 2000,
        variant: variant,
        solid: true,
      });
    },


  }
};
</script>

<style scoped>
.login {


  top: 0;
  width: 90vw;
  margin: 10px 10px 10px 0px;

  padding-left: 90px;
  transition: .3s all ease-in-out;
}


.content {
  padding: 0px 120px 50px 120px;
  width: 98%;
  margin: auto;
  background-size: cover;

}

@media all and (-webkit-min-device-pixel-ratio: 0) and (display-mode: fullscreen) {
  .content {
    padding: 0px 120px 50px 120px;
    width: 98%;
    margin: auto;
  }
}

@media all and (min--moz-device-pixel-ratio: 0) and (display-mode: fullscreen) {
  .content {
    padding: 0px 120px 50px 120px;
    width: 98%;
    margin: auto;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .content {
      padding: 0px 120px 50px 120px;
    }
  }
}

@media (max-width: 767px) {
  .content {
    padding: 0px;
  }
}

.center {

  width: 400px;
  height: 80%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

}

.box-shadow {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.dist_10 {
  margin-bottom: 10px;
}

.dist_30 {
  margin-bottom: 40px;
}

.dist_50 {
  margin-bottom: 50px;
}
</style>