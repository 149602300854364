/* eslint-disable */
<template>
  <div>

    <div class="lookgood">
      LOOKS ALWAYS GOOD ON ALL SCREENS
    </div>

    <div>
      <marquee direction="right">
        Achtung neu !
      </marquee>
    </div>

    <MultiForm />
  </div>
</template>
<script>
import MultiForm from '@/components/MultiForm.vue'
export default {
  name: 'App',
  components: {
    MultiForm
  }
}
</script>

<style scoped>
.lookgood {

  font-family: sans-serif;
  font-size: 4em;
  font-weight: 400;
  line-height: 1.2;
  margin: auto;
  max-width: 70ch;
  padding: 3em 1em;

}
</style>