import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        transfer: {
            json: [],
            unique: ''

        },

        authenticated: false,
        actCustomerID: "",
        actSessionID: ""
    },
    mutations: {
        changeJSON(state, payload) {
            state.transfer.json = payload
        },
        changeUnique(state, payload) {
            state.transfer.unique = payload
        },
        setAuthStatus(state, payload) {
            state.authenticated = payload;
        },
        setActCustomerID(state, payload) {
            state.actCustomerID = payload;
        },
        setActSessionID(state, payload) {
            state.actSessionID = payload;
        },
        setActUserID(state, payload) {
            state.actUserID = payload;
        }

    },
    actions: {},
    modules: {}
});