<template>
	<div id="app">
		
		<Navbar />
		<router-view />
		<Footer />
		<footer>
			<cookie-law theme="base"></cookie-law>
		</footer>
		
	</div>
</template>

<script>
import CookieLaw from 'vue-bootstrap-cookie-law';
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
export default {
	name: 'App',
	components: {
		Navbar,
		Footer,
		CookieLaw,
	},
	methods: {},
};
</script>

<style lang="scss">
/* @import url('http://fonts.cdnfonts.com/css/red-hat-display');

#app {
  font-family: 'Red Hat Display', sans-serif, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
} */
</style>
