<template>
    <div class="center">

        <div style="margin: 50px 10px 10px 10px;">

            <b-card no-body>

                <div style="margin: 10px 10px 10px 10px;">

                    <header>

                        <div class="top-gradient">
                            
                            <h4> Step {{ formPosition + 1 }}</h4>

                        </div>

                    </header>

                    <div :class="animation">
                        <h2>{{ formGroup[formPosition].title }}</h2>

                        <div>
                            <div v-for="(field, index) in formGroup[formPosition].fields" :key="'field'+index">
                                <div class="dist_10"></div>
                                <label>{{ field.label }}</label>
                                <b-form-input type="text" v-model="field.value" required></b-form-input>

                            </div>
                        </div>
                        <div class="dist_10"></div>
                        <div class="dist_10"></div>
                        <div>

                            <b-button  size="sm" variant="outline-primary" class="mr-3" v-if="formPosition +1 <= formGroup.length -1" @click="nextStep">
                                Next
                            </b-button>

                            <b-button  size="sm" variant="outline-primary" class="mr-3" v-if="formPosition >= 1" @click="backStep">
                                Back
                            </b-button>

                            <b-button   size="sm" variant="outline-primary" class="mr-3" v-if="formPosition  === formGroup.length -1" @click="saveForm">
                                Save
                            </b-button>

                            <div class="dist_10"></div>

                            <div>

                               <progress id="file" max="100" :value="progressPercent"> 100% </progress>

                            </div>

                        </div>
                    </div>
                </div>

            </b-card>

            <div class="dist_10"></div>


            <pre class="m-0">{{ formPosition }}</pre>
            <hr />
            <pre class="m-0">{{ formGroup }}</pre>

            <hr />
            <pre class="m-0">{{ formGroup[0].fields[0].value}}</pre>


        </div>
    </div>

</template>
<script>
export default {
    data: () => {
        return {
            formPosition: 0,
            progressPercent: 25,
            animation: 'animate-in',
            formGroup: [
                {
                    title: "Personal Details",
                    fields: [
                        { label: "FirstName", value: "" },
                        { label: "SecondName", value: "" },
                        { label: "Age", value: "" },]
                },
                {
                    title: "Address",
                    fields: [
                        { label: "City", value: "" },
                        { label: "ZipCode", value: "" },
                        { label: "County", value: "" },
                        { label: "State", value: "" },]
                },
                {
                    title: "Academic Details",
                    fields: [
                        { label: "Academic qualification", value: "" },
                        { label: "College Attended", value: "" },
                        { label: "Year of completion", value: "" },]
                },
                {
                    title: "Test Details",
                    fields: [
                        { label: "Street", value: "" },
                        { label: "Notice", value: "" },
                        { label: "Remark", value: "" },]
                },
            ]
        }
    },
    methods: {
        nextStep() {
            this.animation = 'animate-out';
            setTimeout(() => {
                this.animation = 'animate-in';
                this.formPosition += 1;
                this.progressPercent +=25;
            }, 600);
        },

        backStep() {
            this.animation = 'animate-out';
            setTimeout(() => {
                this.animation = 'animate-in';
                this.formPosition -= 1;
                this.progressPercent -=25;
            }, 600);
        },


        saveForm() {

            const result = {};
            this.formGroup.forEach(obj => {
                obj.fields.forEach(field => {
                    result[field.label] = field.value;
                });

            });

            console.log("form data :", result)


        },

    }
}
</script>
<style>
.animation-in {
    transform-origin: left;
    animation: in .6s ease-in-out;
}

.animation-out {
    transform-origin: bottom left;
    animation: out .6s ease-in-out;
}

.center {

    width: 400px;
    height: 80%;
    margin: auto;
    /* position: absolute; */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

}

.top-gradient{

height: 100px;
background: linear-gradient(180deg, #f5d0fe, #f7d7fe, #f8ddfe, #fae4ff, #fbebff, #fcf2ff, #fef8ff, #ffffff);
}

progress {
  accent-color: #F02D65;
  width: -webkit-fill-available;
}

</style>