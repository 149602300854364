import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Magic from "@/views/Magic.vue";
import Register from "@/views/Register.vue";
import Forgot from "@/views/Forgot.vue";
import Test from "@/views/Test.vue";


Vue.use(Router);

export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [

        {
            path: "/login",
            name: "login",
            component: Login
        },

        {
            path: "/home",
            name: "home",
            component: Home
        },

        {
            path: "/magic",
            name: "magic",
            component: Magic
        },

        {
            path: "/forgot",
            name: "forgot",
            component: Forgot
        },

        {
            path: "/test",
            name: "test",
            component: Test
        },

        {
            path: "/register",
            name: "register",
            component: Register
        },

        {
            path: '/',
            redirect: {
                name: "login"
            }
        }
    ]
});