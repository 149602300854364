/* eslint-disable */
<template>
    <div v-if="authenticated" :style="{ backgroundColor: backgroundcolor }" id="app" class="content">

        <UserBox v-if="loggedName" :user="loggedName" :avatar="avatarURL" />

        <div style="margin: 10px 10px 10px 10px;">

            <div>
                <b-form-checkbox v-model="fakeAPIchecked" name="check-button" switch>
                    fake API on/off <b>(Checked: {{ fakeAPIchecked }})</b>
                </b-form-checkbox>
            </div>

            <div class="dist_10"></div>

            <!-- xxx 2 container -->
            <div class="container-fluid mt-2">
                <div class="row">
                    <div class="card-deck">

                        <!-- card 1-->

                        <div class="card mb-4 box-shadow" style="min-width: 22rem; ">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-5">
                                        <div class="icon-big">
                                            <b-icon icon="bar-chart-fill" variant="primary" font-scale="4"></b-icon>
                                        </div>
                                    </div>
                                    <div class="col-7">
                                        <div class="numbers">
                                            <div>
                                                <p class="card-category">ihre letzten Anfragen</p>
                                                <h4 class="card-title big_number">{{ total_response }}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p class="text-muted">aktuell</p>

                                <hr />
                                <!--  <p class="card-text">
                                    Lorem ipsum dolor sit amet, consetetur

                                </p> -->
                                <!--     <p class="card-text">
                                    <small class="text-muted">Last updated 3 mins ago</small>
                                </p> -->
                                <b-button size="sm" v-on:click="getDocuments(limit, (currentPage * limit))"
                                    variant="outline-secondary">refresh</b-button>
                            </div>
                        </div>


                        <!-- card 2-->
                        <div class="card mb-4 box-shadow" style="min-width: 24rem;">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-5">
                                        <div class="icon-big">
                                            <b-icon icon="octagon-fill" variant="primary" font-scale="4"></b-icon>
                                        </div>
                                    </div>
                                    <div class="col-7">
                                        <div class="numbers">
                                            <div>
                                                <p class="card-category">ihre verbrauchten tokens</p>
                                                <h4 class="card-title big_number">{{ total_tokens }}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <p class="text-muted">aktuell</p>

                                <hr />
                                <!--  <p class="card-text">
                                    Lorem ipsum dolor sit amet, consetetur
                                </p> -->
                                <!--     <p class="card-text">
                                    <small class="text-muted">Last updated 3 mins ago</small>
                                </p> -->
                                <b-button size="sm" v-on:click="getOpenAI_requests_total_tokens()"
                                    variant="outline-secondary">refresh</b-button>

                            </div>

                        </div>

                        <!-- card 3-->
                        <div class="card mb-4 box-shadow" style="min-width: 24rem;">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-5">
                                        <div class="icon-big">
                                            <b-icon icon="currency-exchange" variant="primary" font-scale="4"></b-icon>
                                        </div>
                                    </div>
                                    <div class="col-7">
                                        <div class="numbers">
                                            <div>
                                                <p class="card-category">ihre gekauften tokens</p>
                                                <h4 class="card-title big_number">{{ total_tokens }}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <p class="text-muted">aktuell</p>

                                <hr />
                                <!--  <p class="card-text">
                                    Lorem ipsum dolor sit amet, consetetur
                                </p> -->
                                <!--     <p class="card-text">
                                    <small class="text-muted">Last updated 3 mins ago</small>
                                </p> -->
                                <b-button size="sm" v-on:click="listAccounts()"
                                    variant="outline-secondary">refresh</b-button>

                            </div>

                        </div>

                        <!-- card 4-->
                        <div class="card mb-4 box-shadow" style="min-width: 24rem;">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-5">
                                        <div class="icon-big">
                                            <b-icon icon="exclamation-triangle-fill" variant="warning"
                                                font-scale="4"></b-icon>
                                        </div>
                                    </div>
                                    <div class="col-7">
                                        <div class="numbers">
                                            <div>
                                                <p class="card-category">ihre restlichen tokens</p>
                                                <h4 class="card-title big_number">{{ total_tokens }}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <p class="text-muted">aktuell</p>

                                <hr />
                                <!--  <p class="card-text">
                                    Lorem ipsum dolor sit amet, consetetur
                                </p> -->
                                <!--     <p class="card-text">
                                    <small class="text-muted">Last updated 3 mins ago</small>
                                </p> -->
                                <b-button size="sm" v-on:click="listAccounts()"
                                    variant="outline-secondary">refresh</b-button>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <h3>Frage AI Vision</h3>

            <b-button style="margin: 10px 0px 10px 0px" class="btn btn-block" variant="outline-secondary" size="md"
                @click="getOpenAI_requests()">
                Lade Historie</b-button>

            <b-button @click="AIresponse = []" class="btn btn-block mt-2" variant="outline-secondary" size="md">
                <b-icon icon="plus-circle"></b-icon> Neuer chat
            </b-button>

            <div class="dist_10"></div>

            <div>
                <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
                    <b-button v-b-toggle.collapse-1 size="sm" variant="outline-secondary"
                        style="pointer-events: none">Parameter</b-button>

                </span>

                <b-tooltip target="disabled-wrapper">Parameter: <br />GTP-3 Models<br />temperature: 0 - logical<br />
                    0.5 -
                    balanced<br />1 - creative<br />max_tokens: 0 - 4096 (je nach Modell)
                </b-tooltip>
            </div>

            <!-- parameter für openAI -->
            <div class="dist_10"></div>

            <b-form-group label-cols="3" label-cols-lg="3" label-size="sm" label="models" label-for="models">

                <small>
                    <b-button style="float: left; font-size: 0.475rem; margin-left: -40px;" size="sm" class="sm"
                        variant="light" @click="openNewModal()">
                        <b-icon icon="info" scale="1"></b-icon>
                    </b-button>
                </small>

                <div>
                    <b-form-select v-model="models" :options="openAIModels" size="sm"></b-form-select>
                </div>

            </b-form-group>

            <div class="dist_10"></div>

            <b-form-group label-cols="3" label-cols-lg="3" label-size="sm" label="temperature"
                label-for="input-temperature">

                <b-form-input type="text" autocomplete="off" required="required" v-model="temperature" placeholder="0.9"
                    id="input-temperature" size="sm"></b-form-input>


            </b-form-group>

            <div class="dist_10"></div>

            <b-form-group label-cols="3" label-cols-lg="3" label-size="sm" label="max_tokens"
                label-for="input-max_tokens">
                <b-form-input type="text" autocomplete="off" required="required" v-model="max_tokens" placeholder="500"
                    id="input-max_tokens" size="sm"></b-form-input>
            </b-form-group>

            <b-input-group class="mt-3 mb-3">

                <b-form-textarea v-model="AIsearchstring" required placeholder="your question" name="question" rows="3">
                </b-form-textarea>

                <!-- <b-form-input v-model="AIsearchstring"></b-form-input> -->
                <b-input-group-append>
                    <b-button variant="outline-secondary" @click="AIsearchstring = ''">X</b-button>
                    <b-button variant="outline-primary" @click="APIdispatcher(AIsearchstring)">Frage AI
                        Vision</b-button>
                </b-input-group-append>
            </b-input-group>

            <div class="dist_10"></div>


            <div v-if="total_response > 0">
                <b-alert show variant="primary">ihre Anfragen: {{ total_response }}</b-alert>
            </div>

            <div class="dist_10"></div>

            <!-- spinner  -->
            <div v-if="spinnnerStatus">

                <div class="dist_10"></div>

                <div class="center-spinner">

                    <div class="spinner-3">
                    </div>

                </div>
            </div>

            <div class="dist_30"></div>


            <!-- DB item list card openAI -->
            <b-card no-body class="mb-2 box-shadow" align="left" v-for="(data) in AIresponse"
                v-bind:key="data.openai_id">
                <div style="margin: 15px 10px 10px 20px">
                    <b-icon icon="flower3" scale="1" class="mr-3"></b-icon>
                    <!--  <img src="https://m.myapp2go.de/customerpics/10040_1do0yzzm0cgiu0pbzl42_SPK_20x20.png" alt="logo" width="20"
               height="20" class="mr-3" /> -->
                    <small>{{ data.openai_id }}</small>
                    <small>
                        <b-button style="float: right; font-size: 0.475rem" size="sm" class="ml-3 sm" variant="light"
                            @click="() => AIsearchstring = data.request_question">
                            <b-icon icon="arrow-counterclockwise" scale="1"></b-icon>
                        </b-button>

                        <b-button style="float: right; font-size: 0.475rem" size="sm" class="ml-3 sm" variant="light"
                            @click="showDeleteBox_response(data)">
                            <b-icon icon="trash" scale="1"></b-icon>
                        </b-button>

                        <b-button style="float: right; font-size: 0.475rem" size="sm" class="ml-3 sm" variant="light"
                            @click="copyToClipboard(data)">
                            <b-icon icon="clipboard-plus" scale="1"></b-icon>
                        </b-button>

                    </small>

                    <div class="dist_10"></div>
                    <h6 class="mb-0">{{ data.request_question }}</h6>

                    <div class="dist_5"></div>

                    <!-- <div class="typewriter"><span v-html="data.response_text"></span></div> -->

                    <div class="typewriter">
                        <span ref="message" style="white-space: pre-line">{{ data.response_text }}</span>
                    </div>

                    <div class="dist_5"></div>


                    <small class="text-success">Datum/Zeit: {{ data.response_date || new Date(Date.now()) }} </small>

                    <!-- <small class="text-success">create: {{ data.$createdAt }} / update: {{ data.$updatedAt }}</small> -->

                </div>

            </b-card>

            <div class="dist_30"></div>


            <!--            <h3>function test</h3>
            <h6>wait a few seconds for function result ...</h6>
            <b-button style="margin: 10px 0px 10px 0px" class="btn btn-block" variant="outline-primary" size="md"
                @click="doExecuteFunction">function</b-button>

            <pre class="m-0">{{ functionResult }}</pre>

            <hr />

            <h3>test buttons</h3>

            <b-button block variant="outline-dark" @click="logout">
                global Logout</b-button>

            <b-button style="margin: 10px 0px 10px 0px" class="btn btn-block" variant="outline-secondary" size="md"
                @click="deleteAllSessions">
                delete sessions</b-button>

            <b-button style="margin: 10px 0px 10px 0px" class="btn btn-block" variant="outline-secondary" size="md"
                @click="checkLogin()">
                check Login</b-button>


            <b-button style="margin: 10px 0px 10px 0px" class="btn btn-block" variant="outline-secondary" size="md"
                @click="nativeCheck()">
                native function</b-button>


            <b-input-group>
                <b-form-input :type="showPassword ? 'text' : 'password'"></b-form-input>
                <b-input-group-append>
                    <b-button @click="showPassword = !showPassword" size="sm" variant="outline-success">{{ showPassword
        ?
        'Hide ' : 'Show '
}}<b-icon icon="eye"></b-icon>
                    </b-button>
                </b-input-group-append>
            </b-input-group>

            <div class="dist_10"></div>

            <StripeCheckoutForm :amount="100" /> -->


            <!--             <b-button style="margin: 10px 0px 10px 0px" class="btn btn-block" variant="outline-secondary" size="md"
                @click="getFileForView()">
                view file bucket</b-button> -->

            <hr />

            <div class="dist_10"></div>

        </div>


        <div style="margin: 10px 10px 10px 10px;">

            <!-- upload test -->

            <h3>train the model</h3>

            <!--               <b-form-file
              
                :state="Boolean(file1)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                :change="uploadFile"
                ref="file"

                ></b-form-file>
  -->
            <label class="custom-file-upload">Choose File
                <input type="file" @change="uploadFile" ref="file">
            </label>

            <div class="dist_10"></div>

            <div v-if="actURL" class="dist_10">
                <img :src="actURL" class="photo" />
                <div>
                    <span id="actImgName">{{ actImgName }}</span>
                </div>

            </div>

            <div class="dist_10"></div>
            <b-button class="btn-block" variant="outline-secondary" @click="submitFile">upload</b-button>

            <div class="dist_10"></div>
            <b-button class="btn-block" variant="outline-secondary" @click="listFiles()">train</b-button>

            <hr />

            <!-- table files xxx-->

            <div v-if="fileArray.files" class="card mb-4" style="min-width: 22rem; ">

                <div class="card-body">
                    <h5 class="card-title">files Statistics</h5>
                    <div>
                        <b-table responsive small striped :items="fileArray.files" :fields="fields" primary-key="$id"
                            :tbody-transition-props="transProps" id="table-transition"></b-table>
                    </div>
                    <p class="card-text">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                        <br />
                    </p>
                    <!--             <p class="card-text">
                        <small class="text-muted">Last updated 3 mins ago</small>
                    </p> -->
                </div>
            </div>
            <!-- 
      <div v-for="data in docsArray" :key="data.ID">

        <small>{{ data.ID }} - </small>
        <small>{{ data.number }} {{ data.name }} </small>

      </div> -->

            <h3>realtime info</h3>
            <pre class="m-0">{{ realtimePayload }}</pre>

            <!--            <hr />


            <h3>DB query</h3>
            <b-input-group class="mt-3 mb-3">
                <b-form-input v-model="searchstring"></b-form-input>
                <b-input-group-append>
                    <b-button variant="outline-secondary" @click="searchstring = ''">X</b-button>
                    <b-button variant="outline-success" disabled @click="searchItems">Search Array</b-button>
                </b-input-group-append>
            </b-input-group>


            <b-input-group class="mt-3 mb-3">
                <b-form-input v-model="searchstringDB"></b-form-input>
                <b-input-group-append>
                    <b-button variant="outline-secondary" @click="searchstringDB = ''">X</b-button>
                    <b-button variant="outline-success" @click="searchUsers(searchstringDB)">Search in DB </b-button>
                </b-input-group-append>
            </b-input-group>
 -->
            <!--       <b-alert show variant="secondary">
      </b-alert> -->



            <!--            <b-button @click="openNewModal()" variant="outline-primary" class="mb-2">
                <b-icon icon="plus-circle"></b-icon> New
            </b-button>

            <div class="dist_5"></div>

         >
               
            <b-card no-body class="mb-2 box-shadow" align="left" v-for="(data) in filteredList" v-bind:key="data.$id">
                <div style="margin: 15px 10px 10px 20px">
                    <b-icon icon="people" scale="1" class="mr-3"></b-icon>
                    
                    <small>{{ data.$id }}</small>
                    <small>
                        <b-button style="float: right; font-size: 0.475rem" size="sm" class="ml-3 sm" variant="light"
                            @click="() => openEditModal(data)">
                            <b-icon icon="pencil-square" scale="1"></b-icon>
                        </b-button>

                        <b-button style="float: right; font-size: 0.475rem" size="sm" class="ml-3 sm" variant="light"
                            @click="showDeleteBox(data)">
                            <b-icon icon="trash" scale="1"></b-icon>
                        </b-button>
                    </small>

                    <div class="dist_10"></div>
                    <h6 class="mb-0">{{ data.name }}</h6>

                    <div class="dist_5"></div>

                    <small class="text-muted">{{ data.ID }} - {{ data.number }}</small>
                    <div class="dist_5"></div>

                    <small class="text-success">create: {{ data.$createdAt }} / update: {{ data.$updatedAt }}</small>

                </div>

            </b-card>

            <div class="dist_10"></div>

            <b-form-select v-model="limit" :options="pageOptions" size="sm" class="mt-3 mb-3"></b-form-select>

            <div>

                <b-pagination-nav v-model="currentPage" :number-of-pages="pages" @input="handlePageClick(currentPage)"
                    base-url="#" first-number></b-pagination-nav>
            </div>

            <hr /> -->

            <!-- table -->

            <div v-if="userArray.documents" class="card mb-4" style="min-width: 22rem; ">

                <div class="card-body">
                    <h5 class="card-title">table Statistics (node server)</h5>
                    <div>
                        <b-table responsive small striped :items="userArray.documents" :fields="fields"
                            primary-key="$id" :tbody-transition-props="transProps" id="table-transition"></b-table>
                    </div>
                    <p class="card-text">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                        <br />
                    </p>
                    <!--                     <p class="card-text">
                        <small class="text-muted">Last updated 3 mins ago</small>
                    </p> -->
                </div>
            </div>

            <div>

                <!--      // dropdown -->
                <b-dropdown id="dropdown-form" text="Token Limit einstellen" ref="dropdown" class="m-2" size="lg" split
                    split-variant="outline-primary" variant="primary">
                    <b-dropdown-form>

                        <b-form-group label="Token Limit" label-for="dropdown-form-limit" @submit.stop.prevent>
                            <b-form-input id="dropdown-form-limit" size="sm" placeholder="10000">
                            </b-form-input>
                        </b-form-group>



                        <b-form-checkbox class="mb-3">Erinnerung bei Überschreitung</b-form-checkbox>
                        <b-button @click="listAccounts()" variant="primary" size="md">Save</b-button>
                    </b-dropdown-form>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item-button @click="listAccounts()">List accounts</b-dropdown-item-button>
                    <b-dropdown-item-button>Forgot Password?</b-dropdown-item-button>
                </b-dropdown>
            </div>

            <div class="dist_10"></div>

            <StripeCheckoutForm :amount="100" />

        </div>

        <b-button class="top_btn" size="sm" variant="outline-primary" @click="$scrollTop">Top <b-icon icon="arrow-up"
                scale="1"></b-icon>
        </b-button>

        <!-- modal edit window -->
        <b-modal id="edit-modal" size="lg" scrollable title="Update Item" v-model="modalEditShow" hide-footer>
            <h6 class="typo-aw">Item ID: {{ selectedItem.$id }}</h6>

            <div class="dist_10"></div>

            <b-form novalidate>
                <b-form-group label="name">

                    <b-form-input type="text" v-model="selectedItem.name" required placeholder="name" name="name">
                    </b-form-input>

                </b-form-group>

                <b-form-group label="personal number">

                    <b-form-textarea v-model="selectedItem.number" required placeholder="number" name="personal number"
                        rows="1">
                    </b-form-textarea>

                </b-form-group>
                <b-form-group label="address">

                    <b-form-textarea v-model="selectedItem.ID" required placeholder="address" name="address" rows="1">
                    </b-form-textarea>

                </b-form-group>

                <div class="dist_5"></div>

                <small class="text-success">last update: {{ selectedItem.$updatedAt }} </small>
                <div class="dist_10"></div>


            </b-form>

            <b-button class="btn-block" variant="primary" type="submit" @click="updateCustomer(selectedItem)">update
            </b-button>
            <b-button class="btn-block" variant="outline-primary" type="submit" @click="closeEditModal()">cancel
            </b-button>

            <hr />

            <div v-if="filesHref">
                <div v-for="data in filesHref" :key="data.href">

                    <small>{{ data.href }} </small>
                    <div class="dist_10"></div>
                    <small>{{ data.image_name }} </small>
                    <div class="dist_10"></div>
                    <img :src="data.href" alt="image" width="200" height="150" class="mr-3 img-fit" />

                    <hr />
                </div>
            </div>

            <!--             <div v-for="data in customer_imageArray" :key="data.$id">

                <small>{{ data.image_ID}} - image_ID</small>

            </div> -->

        </b-modal>

        <!-- modal new window -->
        <b-modal id="new-modal" size="lg" scrollable title="Parameter Information" v-model="modalNewShow" hide-footer>

            <div class="dist_10"></div>

            <h5>Auswahl des Models</h5>

            <p>text-davinci-003 <br /> Das leistungsfähigste GPT-3-Modell kann jede Aufgabe erledigen, die die anderen
                Modelle können, oft mit höherer Qualität, längerem Output und besserem Anweisungsverfolgung. Unterstützt
                auch das Einfügen von Vervollständigungen innerhalb des Textes. (max_tokens 4000)
            </p>

            <p>text-curie-001 <br /> Sehr leistungsfähig, aber schneller und kostengünstiger als Davinci. (max_tokens
                2048)
            </p>

            <p>text-babbage-001<br /> Fähig einfache Aufgaben zu erledigen, sehr schnell und kostengünstig. (max_tokens
                2048)
            </p>

            <p>text-ada-001<br /> In der Lage, sehr einfache Aufgaben zu erledigen, normalerweise das schnellste Modell
                in der GPT-3-Serie und der niedrigste Kosten. (max_tokens 2048)
            </p>

            <p>code-davinci-002<br /> Am leistungsfähigsten Codex-Modell. Besonders gut darin, natürliche Sprache in
                Code zu übersetzen. Neben dem Abschließen von Code unterstützt es auch das Einfügen von
                Vervollständigungen innerhalb des Codes. (max_tokens 8000)
            </p>

            <p>code-cushman-001<br /> Fast so leistungsfähig wie Davinci Codex, aber etwas schneller. Dieser
                Geschwindigkeitsvorteil kann es für Echtzeitanwendungen vorzuziehen machen. (max_tokens 2048)
            </p>

            <h5>Einstellung der temperatur</h5>

            <p>Welche Sampling-Temperatur soll verwendet werden? Höhere Werte bedeuten, dass das Modell mehr Risiken
                eingehen wird. Versuchen Sie 0,9 für kreativere Anwendungen und 0 (Argmax-Sampling) für solche mit einer
                gut definierten Antwort. 0.5 ist ein Mittelmaß.</p>

            <p>0 - logical<br />
                0.5 - balanced<br />
                1 - creative
            </p>

            <h5>Einstellung der max_Tokens</h5>

            <p>Maximale Anzahl der zurückgelieferten tokens (siehe jeweilige Modelle)</p>

            <div class="dist_10"></div>

            <hr />

            <p>weitere Informationen finden Sie auf der Seite von openAI</p>

            <a href="https://beta.openai.com/docs/models/overview">openAI docs</a>


            <div class="dist_10"></div>


            <b-button class="btn-block" variant="outline-primary" type="submit" @click="closeNewModal()">OK
            </b-button>

        </b-modal>


    </div>
</template>

<script>

import { Client, Account, Databases, Query, Avatars, Storage, Functions } from 'appwrite';

import { uuid } from "vue-uuid";


import UserBox from "@/components/UserBox.vue";
import StripeCheckoutForm from "@/components/StripeCheckoutForm.vue";

import { getAllUsers, getDocuments } from '@/services/UserService'; // for node.js reads

// import { getAIResponse } from '@/services/openAIRequester'; // for node.js reads

import Myapp2goTrackerService from "@/services/Myapp2goTrackerService";


/*   var DBCollectionArray = new Map();
  DBCollectionArray.set('users', '632d9608f38190b05abf');

  const datebaseID = "datebaseID";
  const endpoint = 'http://localhost/v1';
  const project =  '632d955c695abe307b60';
  
 */

// PROD
var DBCollectionArray = new Map();
DBCollectionArray.set('customer', '63446cd877f69df94aca');
DBCollectionArray.set('customer_images', '634d6795e84174a304f4');
DBCollectionArray.set('customer_keys', '635256b413c28b9de0c8');
DBCollectionArray.set('openai_response', '63c526db18cd7f278aaf');
DBCollectionArray.set('openai_user_sum_total_tokens', '63caa5202c4308bbcade');


const datebaseID = "63446ca755a041305f7f";
const endpoint = 'https://bf2a562.online-server.cloud/v1';
const project = '63446724a6d27e696227';
const bucketID = '6346ac871bc3563be215';


const client = new Client()
    .setEndpoint(endpoint) // Replace with your endpoint
    .setProject(project); // Replace with your API Key


const databases = new Databases(client);

export default {
    name: "Home",
    components: {
        UserBox,
        StripeCheckoutForm
    },
    beforeMount() {
        this.authenticated = this.$store.state.authenticated;
        //this.checkMagicLogin();


    },

    mounted() {
        document.title = "my AI";
    },

    data: function () {
        return {

            backgroundcolor: "#fafdff",

            transProps: {
                // Transition name
                name: 'flip-list'
            },
            fields: [
                { key: '$id', sortable: true },
                { key: 'name', sortable: true },
                { key: 'sizeOriginal', sortable: true }
            ],

            tasks: [],

            docsArray: [],
            docsArrayOriginal: [],
            userArray: [],
            fileArray: [],
            customer_imageArray: [],
            filesHref: [],

            total: 0,
            total_response: 0,
            total_tokens: 0,
            userprofile: [],
            newTask: '',
            form: {},
            uuid: null,
            functionResult: "",

            currentSessionID: "",
            loggedName: "",

            searchstring: "",
            AIsearchstring: "",
            searchstringDB: "",
            AIresponse: [],
            AItokens_per_user: [],

            modalEditShow: false,
            modalNewShow: false,
            selectedItem: {},
            deleteBox: "",
            countAccounts: 0,
            realtimePayload: [],
            avatarURL: "",
            actFileID: "",
            actURL: "",
            actImgName: "",
            tracker: "",

            bodytest: "bodytest string",

            showPassword: false,

            file1: "",

            pages: 1,
            currentPage: 1,
            limit: 25,
            offset: 0,
            pageOptions: [
                { value: null, text: 'Please select an option' },
                { value: '5', text: '5 lines' },
                { value: '10', text: '10 lines' },
                { value: '15', text: '15 lines' },
                { value: '20', text: '20 lines' },
                { value: '25', text: '25 lines' }

            ],
            openAIModels: ["text-davinci-003", "code-davinci-002", "code-cushman-001", "text-curie-001", "text-babbage-001", "text-ada-001", "ada:ft-xpediter:pv-2023-01-21-16-00-45"],
            models: "text-davinci-003",
            temperature: 0,
            max_tokens: 500,
            spinnnerStatus: false,
            fakeAPIchecked: false

        }
    },
    methods: {

        listAccounts: function () {

            const account = new Account(client);

            const promise = account.listSessions();

            promise.then(response => {

                console.log(response);

                this.countAccounts = response.total;

                console.log(this.countAccounts);

                this.makeToast("info", JSON.stringify(response), "accounts sessions");

            }, error => {
                console.log(error);
                this.makeToast("danger", error.message, "session listing failed");
            });


        },

        getDocuments: function (pLimit, pOffset) {

            //console.log("Collection get: ", DBCollectionArray.get('customer'));

            const promise = databases.listDocuments(datebaseID, DBCollectionArray.get('customer'),
                [
                    Query.limit(pLimit),
                    Query.offset(pOffset),
                    Query.orderAsc("name")
                ]);

            promise.then((response) => {
                console.log(response); // Success

                this.docsArray = response.documents

                this.total = response.total;

                this.pages = Math.floor(this.total / this.limit) + 1; // Anzahl Seiten berechnen f. Pagination

                //this.offset = this.currentPage * this.limit;

                console.log("pages ---: ", this.pages);

                this.docsArrayOriginal = this.docsArray; // merken array 

            }, function (error) {
                console.log(error); // Failure 
            });

        },

        getCustomerImages: function (pActCustomerID) {

            const promise = databases.listDocuments(datebaseID, DBCollectionArray.get('customer_images'),
                [
                    Query.equal("customer_ID", pActCustomerID),

                ]);

            promise.then((response) => {

                console.log("Customer Images list : ", response); // Success

                this.customer_imageArray = response.documents

                if (response.total > 0) {

                    console.log("Customer Image Array---: ", this.customer_imageArray);

                    this.getFileForView(this.customer_imageArray);
                }


            }, function (error) {
                console.log(error); // Failure
            });


        },


        getOpenAI_requests_total_tokens: function () {

            let sum = 0;

            const promise = databases.listDocuments(datebaseID, DBCollectionArray.get('openai_user_sum_total_tokens'),
                [
                    Query.equal("user_id", this.$store.state.actUserID),
                    // Query.orderDesc("response_date ")

                ]);

            promise.then((response) => {


                this.AItokens_per_user = response.documents;

                console.log("sum total tokens array ---: ", this.AItokens_per_user);


                this.AItokens_per_user.forEach(item => {
                    sum += parseInt(item.total_tokens);
                });


                this.total_tokens = sum;


            }, function (error) {
                console.log(error); // Failure
            });


        },


        getOpenAI_requests: function () {

            this.spinnnerStatus = true;

            const promise = databases.listDocuments(datebaseID, DBCollectionArray.get('openai_response'),
                [
                    Query.equal("user_id", this.$store.state.actUserID),
                    // Query.orderDesc("response_date ")

                ]);

            promise.then((response) => {


                console.log("openAI response : ", response); // Success

                this.AIresponse = response.documents;

                const reversed = [...this.AIresponse].reverse(); // array umdrehen, neueste vorn

                this.AIresponse = reversed;

                this.total_response = response.total;

                this.spinnnerStatus = false;



            }, function (error) {
                console.log(error); // Failure
            });


        },


        getFileForView: function (pImageArray) {

            const storage = new Storage(client);

            var jsonData = [];

            var newJSONData = [];

            pImageArray.forEach(function (element) {

                const result = storage.getFilePreview(bucketID, element.image_ID);

                console.log("--- image for view in loop : ", result.href); // Success
                console.log("--- image result : ", JSON.stringify(result)); // Success
                console.log("--- image name : ", element.image_name); // Success

                jsonData["href"] = result.href;
                jsonData["image_name"] = element.image_name;

                newJSONData.push(jsonData);

                jsonData = [];

                console.table(newJSONData);

            });

            this.filesHref = newJSONData;


        },

        APIdispatcher: async function (pSearchstring) {


            if (this.fakeAPIchecked) {

                this.nodeAPIgetAIRequest_fake(pSearchstring);

            } else {

                this.nodeAPIgetAIRequest(pSearchstring);

            }


        },


        nodeAPIgetAIRequest: async function (pSearchstring) {

            if (this.AIsearchstring === "") {

                this.makeToast("danger", "Warnung", "bitte geben Sie eine Frage ein!");

                return;
            }


            this.spinnnerStatus = true;
            // http://85.215.100.108:3080/
            let response = await fetch('http://85.215.100.108:3080/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    message: pSearchstring,
                    model: this.models,
                    temperature: parseInt(this.temperature),
                    max_tokens: parseInt(this.max_tokens)

                })
            });

            let data = await response.json();

            console.log(data.choices[0].text);

            if (data) {

                this.AIresponse.unshift({ "response_text": data.choices[0].text, "openai_id": data.id, "request_question": pSearchstring });

                this.postNewOpenAI_sum_tokens(this.$store.state.actUserID, data.usage.total_tokens);

                this.delay().then(() => this.postNewOpenAI_Response(data.id, this.$store.state.actUserID, data.choices[0].text, data.usage.total_tokens, data.model, pSearchstring));

                this.total_tokens += data.usage.total_tokens;

                // this.postNewOpenAI_Response(data.id, this.$store.state.actUserID, data.choices[0].text, data.usage.total_tokens, data.model, pSearchstring);


                console.log(this.AIresponse);

                this.spinnnerStatus = false;

                this.total_response += 1;

            }


        },

        nodeAPIgetAIRequest_fake: async function (pSearchstring) {

            this.uuid = uuid.v4();

            if (this.AIsearchstring === "") {

                this.makeToast("danger", "Warnung", "bitte geben Sie eine Frage ein!");

                return;
            }

            this.spinnnerStatus = true;
            // http://85.215.100.108:3080/
            let response = await fetch('http://localhost:3004/completions', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }

            });

            let data = await response.json();

            console.log(data.choices[0].text);

            if (data) {

                data.id = this.uuid;

                data.choices[0].text = this.randomText();


                this.AIresponse.unshift({ "response_text": data.choices[0].text, "openai_id": data.id, "request_question": pSearchstring });

                this.postNewOpenAI_sum_tokens(this.$store.state.actUserID, data.usage.total_tokens);

                this.delay(1000).then(() => this.postNewOpenAI_Response(data.id, this.$store.state.actUserID, data.choices[0].text, data.usage.total_tokens, data.model, pSearchstring));

                this.total_tokens += data.usage.total_tokens;

                // this.postNewOpenAI_Response(data.id, this.$store.state.actUserID, data.choices[0].text, data.usage.total_tokens, data.model, pSearchstring);


                console.log(this.AIresponse);

                this.spinnnerStatus = false;

                this.total_response += 1;

            }


        },

        nodeAPIgetAllUsers: function () {
            getAllUsers().then(response => {
                console.log("node user --: ", response)
                this.userArray = response

            })
        },

        nodeAPIgetDocuments: function () {
            getDocuments().then(response => {
                console.log("node documents --: ", response)
                this.userArray = response

            })
        },

        getAvatar: function (pName) {

            const avatars = new Avatars(client);

            const result = avatars.getInitials(pName, 100, 100, '6E36D6');

            console.log("Avatar: ", JSON.stringify(result), this.avatarURL); // Success

            this.avatarURL = result.href;

        },

        searchUsers: function (pSerchString) {

            const promise = databases.listDocuments(datebaseID, DBCollectionArray.get('customer'), [

                Query.search("name", pSerchString)

            ]);

            promise.then((response) => {
                console.log(response); // Success

                this.total = response.total;

                this.pages = Math.round(this.total / this.limit); // Anzahl Seiten berechnen f. Pagination

                console.log("pages search ---: ", this.pages);

                this.docsArray = response.documents

                this.docsArrayOriginal = this.docsArray;

            }, function (error) {
                console.log(error); // Failure
            });

        },

        postNewCustomer: function () {

            this.uuid = uuid.v4();

            const promise = databases.createDocument(datebaseID, DBCollectionArray.get('customer'), `${this.uuid}`, {

                name: this.form.name,
                number: this.form.number,
                ID: this.form.ID

            });

            promise.then((response) => {
                console.log(response); // Success

                this.getDocuments(this.limit, 0);

                this.closeNewModal();

                this.makeToast("success", response.$id, "saved successfully");


            }, (error) => {

                this.makeToast("danger", error.message, "post failed");
                console.log(error); // Failure
            });

        },

        postNewCustomer_Images: function (imageID, customerID, pImageName) {

            this.uuid = uuid.v4();

            var date = new Date(Date.now());

            const promise = databases.createDocument(datebaseID, DBCollectionArray.get('customer_images'), `${this.uuid}`, {

                image_ID: imageID,
                customer_ID: customerID,
                image_name: pImageName,
                image_date: date.toISOString()

            });

            promise.then((response) => {
                console.log(response); // Success


                this.makeToast("success", response.$id, "saved customer_images successfully");


            }, (error) => {

                this.makeToast("danger", error.message, "post customer_images failed");
                console.log(error); // Failure
            });

        },

        postNewOpenAI_Response: function (pOpenAI_id, pUser_id, pResponse_text, pTotalTokens, pOpenAI_model, pQuestion) {

            this.uuid = uuid.v4();

            var date = new Date(Date.now());

            const promise = databases.createDocument(datebaseID, DBCollectionArray.get('openai_response'), `${this.uuid}`, {

                openai_id: pOpenAI_id,
                user_id: pUser_id,
                total_tokens: pTotalTokens,
                response_date: date.toISOString(),
                openai_model: pOpenAI_model,
                response_text: pResponse_text,
                request_question: pQuestion

            });

            promise.then((response) => {
                console.log(response); // Success


                //this.makeToast("success", response.$id, "saved OpenAI response successfully");


            }, (error) => {

                this.makeToast("danger", error.message, "post OpenAI response failed");
                console.log(error); // Failure
            });

        },

        postNewOpenAI_sum_tokens: function (pUser_id, pTotalTokens) {

            this.uuid = uuid.v4();

            var date = new Date(Date.now());

            const promise = databases.createDocument(datebaseID, DBCollectionArray.get('openai_user_sum_total_tokens'), `${this.uuid}`, {

                user_id: pUser_id,
                total_tokens: pTotalTokens


            });

            promise.then((response) => {

                console.log("sum tokens geschrieben ---: ", response); // Success


                //this.makeToast("success", response.$id, "saved OpenAI response successfully");


            }, (error) => {

                this.makeToast("danger", error.message, "post openAi sum_tokens failed");
                console.log(error); // Failure
            });

        },

        updateCustomer: function (pItem) {

            const promise = databases.updateDocument(datebaseID, DBCollectionArray.get('customer'), `${pItem.$id}`, {

                name: pItem.name,
                number: pItem.number,
                ID: pItem.ID

            });

            promise.then((response) => {
                console.log(response); // Success

                this.makeToast("success", response.$id, "update successfull");

                this.closeEditModal();

                this.getDocuments(this.limit, 0);


            }, (error) => {

                this.makeToast("danger", error.message, "update failed");
                console.log(error); // Failure
            });

        },

        logout: function () {

            const account = new Account(client);

            const promise = account.deleteSession(this.$store.state.actSessionID);

            promise.then((response) => {
                console.log(response); // Success

                this.$store.commit("setActSessionID", "");

                this.loggedName = "";
                this.avatarURL = "";

                this.authenticated = false;
                this.$store.commit("setAuthStatus", false);

                this.makeToast("success", JSON.stringify(response), "Logout successful");

                this.delay(1000).then(() => this.$router.push({ name: "login" }));

                //alert("Login OK: " ,response.$id);
                //this.makeToast("success", JSON.stringify(response), "Logout successful");

            }, (error) => {
                console.log(error); // Failure
                //alert(error.message);
                this.makeToast("danger", JSON.stringify(error), "Logout failed");
                //this.makeToast("danger", error.message, "Login failed");
            });

        },

        deleteAllSessions: function () {

            const account = new Account(client);

            const promise = account.deleteSessions();

            promise.then((response) => {
                console.log(response); // Success


                this.loggedName = "";
                this.avatarURL = "";


                //alert("Login OK: " ,response.$id);
                this.makeToast("success", JSON.stringify(response), "all sessions deleted successful");

            }, (error) => {
                console.log(error); // Failure
                //alert(error.message);
                this.makeToast("danger", JSON.stringify(error), "sessions deleting failed");
                //this.makeToast("danger", error.message, "Login failed");
            });

        },

        checkLogin() {

            const account = new Account(client);

            const promise = account.get();

            promise.then((response) => {

                console.log("---Logged in: ", response); // Success

                this.makeToast("success", JSON.stringify(response), "you are already logged in");

                this.userprofile = response;

                this.loggedName = response.email; // if logged in already loggedName makes userBox active

                this.avatarURL = "";
                this.getAvatar(this.userprofile.name); // make avatar with name


            }, (error) => {
                console.log(error); // Failure

                this.makeToast("danger", JSON.stringify(error.message), "you are not logged in!");

                //alert(error.message);
                console.error(error);

            });

        },

        doRealtime() {

            client.subscribe(['documents', 'files'], response => {
                // Callback will be executed on changes for documents A and all files.
                //console.log("realtime :", response);
                this.realtimePayload = response.payload;
            });

        },

        openEditModal(item) {
            this.$bvModal.show("edit-modal");
            this.selectedItem = item;

            this.$store.commit("setActCustomerID", item.$id);

            this.filesHref = [];
            this.getCustomerImages(item.$id);

        },

        closeEditModal() {
            this.$bvModal.hide("edit-modal");
            this.selectedItem = {};
            this.filesHref = [];
        },

        showEditModal() {
            this.modalEditShow = true;

            //this.$store.commit("INCREMENT_COUNT");
        },

        openNewModal() {
            this.$bvModal.show("new-modal");
            this.form = {};

        },

        closeNewModal() {
            this.$bvModal.hide("new-modal");
            this.form = {};
        },

        showNewModal() {
            this.modalNewShow = true;

            //this.$store.commit("INCREMENT_COUNT");
        },

        makeToast(variant = null, pMessage, pText) {
            this.$bvToast.toast(`ID:  ${pMessage}`, {
                title: pText,
                toaster: "b-toaster-bottom-full",
                autoHideDelay: 2000,
                variant: variant,
                solid: true,
            });
        },

        searchItems() {
            this.docsArray = this.docsArrayOriginal.filter((entry) => {
                return entry.name
                    .toLowerCase()
                    .includes(this.searchstring.toLowerCase());
            });
        },

        deleteListItem(actIndex) {
            this.docsArray.splice(
                this.docsArray.indexOf(actIndex), 1

            );

            this.showEditModal();
        },

        uploadFile() {

            this.Images = this.$refs.file.files[0];

            let imgSrc = URL.createObjectURL(this.Images);
            this.actImgName = this.Images.name;

            console.log("---upload file name: ", this.actImgName); // Success

            this.actURL = imgSrc;
        },

        submitFile() {

            this.uuid = uuid.v4();

            const storage = new Storage(client);

            const promise = storage.createFile(bucketID, `${this.uuid}`, this.Images);

            promise.then((response) => {

                console.log("---uploades OK: ", response); // Success

                this.actFileID = response.$id;

                this.actURL = ""; // Bild ausblenden nach erf. upload

                this.makeToast("success", this.actFileID, "upload successful");


                console.log("--- akt ImgName: ", this.actImgName); // Success

                console.log("---uploades OK - actFile ID: ", this.actFileID); // Success

                console.log("---uploades OK - actCustomer ID: ", this.$store.state.actCustomerID); // Success


                if (this.$store.state.actCustomerID) {

                    this.postNewCustomer_Images(this.actFileID, this.$store.state.actCustomerID, this.actImgName); // write join table customer_images
                }

                //this.listFiles();


            }, (error) => {
                console.log(error); // Failure

                this.makeToast("danger", JSON.stringify(error.message), "upload successful!");

                //alert(error.message);
                console.error(error);

            });

        },

        listFiles() {

            const storage = new Storage(client);

            const promise = storage.listFiles(bucketID);

            promise.then((response) => {

                console.log("--- file list ", response); // Success

                this.fileArray = response;

            }, (error) => {
                console.log(error); // Failure
                //alert(error.message);
            });

        },


        showDeleteBox(pData) {
            this.deleteBox = '';

            this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete:  ${pData.$id} - ${pData.name}`, {
                title: 'Please Confirm',
                size: 'lg',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {

                    this.boxTwo = value
                    console.log("value box: ", value);

                    if (value) {

                        this.docsArray.splice(
                            this.docsArray.indexOf(pData), 1
                        );
                        const promise = databases.deleteDocument(datebaseID, DBCollectionArray.get('customer'), `${pData.$id}`)

                        promise.then((response) => {
                            console.log(response); // Success

                            this.getDocuments(this.limit, 0);

                            this.makeToast("success", pData.$id, "deleted successfully");

                        }, (error) => {

                            this.makeToast("danger", error.message, "deleting failed");
                            console.log(error); // Failure
                        });

                    }

                })
                .catch(err => {

                    console.log(err)
                    // An error occurred
                })
        },

        showDeleteBox_response(pData) {

            this.deleteBox = '';

            this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete:  ${pData.$id} - ${pData.openai_id}`, {
                title: 'Please Confirm',
                size: 'lg',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {

                    this.boxTwo = value
                    console.log("value box: ", value);

                    if (value) {

                        this.AIresponse.splice(
                            this.AIresponse.indexOf(pData), 1
                        );
                        const promise = databases.deleteDocument(datebaseID, DBCollectionArray.get('openai_response'), `${pData.$id}`)

                        promise.then((response) => {
                            console.log(response); // Success

                            this.getOpenAI_requests();

                            this.makeToast("success", pData.$id, "item deleted successfully");

                        }, (error) => {

                            this.makeToast("danger", error.message, "item deleting failed");
                            console.log(error); // Failure
                        });

                    }

                })
                .catch(err => {

                    console.log(err)
                    // An error occurred
                })
        },


        async writeTracker() {

            var trackerFormData = new FormData();

            trackerFormData.append("nr", "10063"); // fix ID AI Vision
            trackerFormData.append("geo", 1);
            trackerFormData.append("initial", 1);

            // this.status = " ... Sending - please stand by ...";

            this.tracker = await Myapp2goTrackerService.service.writeTracker(
                trackerFormData
            );


        },

        delay(time) {
            return new Promise(resolve => setTimeout(resolve, time));
        },

        handlePageClick(pCurrPage) {

            if (pCurrPage === "1") {

                this.getDocuments(this.limit, 0);

                console.log("offset wenn currP = 1 ---: ", ((pCurrPage * this.limit) - this.limit));

            } else {

                this.getDocuments(this.limit, ((pCurrPage * this.limit)) - this.limit);

                console.log("offset wenn currP != 1 ---: ", ((pCurrPage * this.limit) - this.limit));
            }


        },

        nativeCheck() {
            /*  if ('vibrate' in navigator) {
                   window.navigator.vibrate(200);
             }  */

            Notification.requestPermission().then((result) => {
                console.log(result);
            });

            const generateRandomUUID = (a) => (a ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, generateRandomUUID));

            if (Notification.permission === 'granted') {


                const text = generateRandomUUID();
                const notification = new Notification('Info', { body: text });

                console.log(notification);
            }

        },

        copyToClipboard(pData) {

            this.$clipboard(pData.response_text);

            this.makeToast("success", pData.openai_id, "copied to clipboard!");


        },

        randomText() {
            let text = '';
            for (let i = 0; i < 100; i++) {
                text += String.fromCharCode(Math.floor(Math.random() * (122 - 97 + 1)) + 97);
                if ((i + 1) % 10 === 0) {
                    text += ' ';
                }
            }
            return text;
        }

    }, // methods end

    computed: {
        filteredList() {
            return this.docsArrayOriginal.filter((entry) => {
                return entry.name
                    .toLowerCase()
                    .includes(this.searchstring.toLowerCase());
            });
        },
        dataArrayCounting: function () {
            return this.filteredList.length;
        },
    },

    created: function () {
        //this.login();

        this.checkLogin();
        //this.getDocuments(this.limit, 0);
        //this.listAccounts();
        this.getOpenAI_requests_total_tokens()
        this.doRealtime();
        this.writeTracker();
        //document.designMode = 'on';


    },

    watch: {

        realtimePayload: function (realtimePayload) {

            this.makeToast("info", JSON.stringify(realtimePayload), "realtime events");

        },

        limit: function () {

            if (this.currentPage === "1") {

                this.getDocuments(this.limit, 0);

                console.log("offset wenn currP = 1 ---: ", "0");

            } else {

                this.getDocuments(this.limit, ((this.currentPage * this.limit)) - this.limit);

                console.log("offset wenn currP != 1 ---: ", ((this.currentPage * this.limit) - this.limit));
            }

        },
    },

}
</script>


<style lang="scss">
.header {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
}

.content {
    padding: 0px 120px 50px 120px;
    width: 98%;
    margin: auto;
}

.navbar-brand {
    font-size: 35px !important;
    font-weight: 500;
}

@media all and (-webkit-min-device-pixel-ratio: 0) and (display-mode: fullscreen) {
    .content {
        padding: 0px 120px 50px 120px;
        width: 98%;
        margin: auto;
    }
}

@media all and (min--moz-device-pixel-ratio: 0) and (display-mode: fullscreen) {
    .content {
        padding: 0px 120px 50px 120px;
        width: 98%;
        margin: auto;
    }
}

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .content {
            padding: 0px 120px 50px 120px;
        }
    }
}

@media (max-width: 767px) {
    .content {
        padding: 0px;
    }
}

.dist_5 {
    margin-bottom: 5px;
}

.dist_10 {
    margin-bottom: 10px;
}

.dist_30 {
    margin-bottom: 30px;
}

.dist_200 {
    margin-bottom: 200px;
}


.aw-bg {
    background-color: #F02D65;
}

.btn-aw {
    color: #fff;
    background-color: #F02D65;
    border-color: #F02D65;
}

.btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.typo-aw {

    color: #F02D65;

}


.typo-ai {

    color: #6E36D6;

}


.numbers {

    text-align: right;

}

.big_number {

    font-weight: 300;
    line-height: 30px;
    font-size: 2.5rem;

}

.card .card-category,
.card label {
    font-size: 14px;
    font-weight: 400;
    color: #9a9a9a;
    margin-bottom: 10px;
}

.top_btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;

}

table#table-transition .flip-list-move {
    transition: transform 1s;
}

.photo {

    width: 100%;

}

.img-fit {

    height: 100%;
    width: 100%;
    object-fit: contain;
    /*   
    max-width: 100%;
    max-height: 100%;
    height: auto; */
}


.img-fit:hover {

    transform: scale(.95);

}


.navbar {

    background: transparent;
    backdrop-filter: blur(10px);
}

.navbar-light .navbar-brand {
    color: #6E36D6 !important;

}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.box-shadow {
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.typewriter {
    overflow: hidden;
    /* Ensures the content is not revealed until the animation */
    border-right: .15em solid #6E36D6;
    /* The typwriter cursor */
    // white-space: nowrap;
    /* Keeps the content on a single line */
    margin: 0 auto;
    /* Gives that scrolling effect as the typing happens */
    letter-spacing: .1em;
    /* Adjust as needed */
    animation:
        typing 3.5s steps(40, end),
        blink-caret .75s step-end infinite;
    font-size: 0.9em;
}

/* The typing effect */
@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: #6E36D6;
    }
}


.spinner-3 {
    width: 80px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #6E36D6;
    --_m:
        conic-gradient(#0000 10%, #000),
        linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: s3 1s infinite linear;
}

@keyframes s3 {
    to {
        transform: rotate(1turn)
    }
}

.center-spinner {

    display: flex;
    justify-content: center;
}
</style>