/* eslint-disable */
<template>
  <!-- <div :style="{ backgroundColor: backgroundcolor }" class="content"> -->

  <div :style="bgProps" class="content">

    <div class="center pt-3">

      <div class="dist_30"></div>


      <b-card no-body class="mb-2 box-shadow" align="left">
        <div class="card-body">

          <h3 class="typo-ai">AI Vision</h3>

          <hr />
          <h4 class="card-title">Log In</h4>

          <b-form v-if="show">
            <b-form-group id="input-group-1" label-for="input-1">
              <b-form-input id="input-1" v-model="form.email" type="email" placeholder="Enter email" required>
              </b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" label-for="input-2">
              <b-form-input type="password" id="input-2" v-model="form.password" placeholder="Enter password" required>
              </b-form-input>
            </b-form-group>

            <b-button v-on:click="doLogin()" class="btn btn-block" variant="outline-primary">
              <b-icon icon="arrow-right-square"></b-icon>
              Sign In
            </b-button>
            <div class="dist_10"></div>

            <!--      <b-button size="sm" type="reset" variant="outline-warning">Reset</b-button> -->
          </b-form>

          <router-link to="/register">not registered yet?</router-link>



          <hr />

          <p class="card-text">
            <small class="text-muted">forgot password?</small>
          </p>


          <b-form>
            <b-form-group description="get a email with link to change password">

              <b-form-input type="text" v-model="form.forgot_pw" required placeholder="email" name="magicEmail">
              </b-form-input>

            </b-form-group>

          </b-form>

          <b-button class="btn btn-block" variant="outline-success" v-on:click="forgotPassword()">
            <b-icon icon="file-lock"></b-icon> Request New Password
          </b-button>

          <hr />

          <div class="dist_10"></div>


          <!--    <b-button class="btn btn-block" variant="outline-info" v-on:click="gotoRegister()">
            <b-icon icon="person-fill"></b-icon> Register
          </b-button> -->

          <hr />

          <h5 class="card-title">get magic login email</h5>
          <b-form>
            <b-form-group description="get a magic email with link to login">

              <b-form-input type="text" v-model="form.magicEmail" required placeholder="email" name="magicEmail">
              </b-form-input>

            </b-form-group>

          </b-form>

          <b-button style="margin: 10px 0px 10px 0px" class="btn btn-block" variant="outline-primary" size="md"
            @click="doMagicLogin">send magic URL</b-button>

        </div>
      </b-card>

    </div>
  </div>
</template>

<script>

import { Client, Account } from 'appwrite';

import { uuid } from "vue-uuid";

// PROD
var DBCollectionArray = new Map();
DBCollectionArray.set('users', '63446cd877f69df94aca');

const endpoint = 'https://bf2a562.online-server.cloud/v1';
const project = '63446724a6d27e696227';

const client = new Client()
  .setEndpoint(endpoint) // Replace with your endpoint
  .setProject(project); // Replace with your API Key

export default {
  name: "Login",
  beforeMount() {
    this.authenticated = this.$store.state.authenticated;
  },
  data() {
    return {

      backgroundcolor: "#fafdff",

      form: {},

      show: true,

      currentSessionID: "",

      bgProps: {

        backgroundImage: `url(${require('@/assets/bg_data_extraction.png')})`
      }


    };
  },
  /*  mixins: [requestsMixin], */

  methods: {

    doLogin: function () {

      const account = new Account(client);

      const promise = account.createEmailSession(this.form.email, this.form.password);

      promise.then((response) => {

        console.log(response); // Success

        //console.log("Login OK User ID: ", response.userId);

        this.currentSessionID = response.$id;

        this.$store.commit("setAuthStatus", true);

        this.$store.commit("setActSessionID", this.currentSessionID);

        this.$store.commit("setActUserID", response.userId);

        this.$emit("authenticated", true);

        this.$router.push({ name: "home" });

        //this.makeToast("success", JSON.stringify(response), "Login successful");


      }, (error) => {
        console.log(error); // Failure
        //alert(error.message);

        this.makeToast("danger", error.message, "Login failed");
        //this.makeToast("danger", error.message, "Login failed");

      });

    },

    gotoRegister: function () {

      this.$router.push({ name: "register" });


    },

    doMagicLogin: function () {

      this.uuid = uuid.v4();

      const account = new Account(client);

      // const promise = account.createMagicURLSession('6330150f8d06f98de782', 'ralf.borde@icloud.com'); 'https://app.myapp2go.de/magic'

      const promise = account.createMagicURLSession(`${this.uuid}`, `${this.form.magicEmail}`, 'https://app.myapp2go.de/magic');

      promise.then((response) => {

        //alert("Login OK: " ,response.$id);
        this.makeToast("success", JSON.stringify(response), "Magic URL send successful!");

      }, (error) => {
        console.log(error); // Failure

        //alert("Login OK: " ,response.$id);
        this.makeToast("danger", error.message, "Magic failed");
        //this.makeToast("danger", error.message, "Login failed");
      });

    },

    forgotPassword: function () {

      this.uuid = uuid.v4();

      const account = new Account(client);

      // const promise = account.createMagicURLSession('6330150f8d06f98de782', 'ralf.borde@icloud.com'); 'https://app.myapp2go.de/magic'

      const promise = account.createRecovery(`${this.form.forgot_pw}`, 'https://app.myapp2go.de/forgot');

      promise.then((response) => {
        console.log(response); // Success

        this.makeToast("success", JSON.stringify(response), "Password request send successful!");

      }, (error) => {
        console.log(error); // Failure

        //alert("Login OK: " ,response.$id);
        this.makeToast("danger", error.message, "Password request  failed");
        //this.makeToast("danger", error.message, "Login failed");
      });

    },

    makeToast(variant = null, pMessage, pText) {
      this.$bvToast.toast(`ID: ${pMessage}`, {
        title: pText,
        toaster: "b-toaster-bottom-full",
        autoHideDelay: 2000,
        variant: variant,
        solid: true,
      });
    },


  },
};
</script>

<style scoped>
.login {


  top: 0;
  width: 90vw;
  margin: 10px 10px 10px 0px;

  padding-left: 90px;
  transition: .3s all ease-in-out;
}


.content {
  padding: 0px 120px 50px 120px;
  width: 98%;
  margin: auto;
  background-size: cover;

}

/* 
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: 400px;
  margin-top: 55px;
  
} */

@media all and (-webkit-min-device-pixel-ratio: 0) and (display-mode: fullscreen) {
  .content {
    padding: 0px 120px 50px 120px;
    width: 98%;
    margin: auto;
  }
}

@media all and (min--moz-device-pixel-ratio: 0) and (display-mode: fullscreen) {
  .content {
    padding: 0px 120px 50px 120px;
    width: 98%;
    margin: auto;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .content {
      padding: 0px 120px 50px 120px;
    }
  }
}

@media (max-width: 767px) {
  .content {
    padding: 0px;
  }
}

.center {

  /*   display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  transition: .3s all ease-in-out;
  */

  width: 400px;
  height: 80%;
  margin: auto;
  /* position: absolute; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

}

a {

  font-size: 13px !important;

}

.box-shadow {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.dist_10 {
  margin-bottom: 10px;
}

.dist_30 {
  margin-bottom: 40px;
}

.dist_50 {
  margin-bottom: 50px;
}

.typo-ai {

  color: #6E36D6;
  text-align: center;

}
</style>