<template>
  <div>
    <stripe-checkout ref="checkoutRef" mode="payment" :pk="publishableKey" :line-items="lineItems"
      :success-url="successURL" :cancel-url="cancelURL" @loading="(v) => (loading = v)"
      @token="tokenCreated"></stripe-checkout>

    <b-button style="margin: 10px 0px 10px 0px" class="btn btn-block" variant="outline-info" size="md"
      @click="checkout()">
      {{ (amount / 100).toFixed(2) }} - ihre token kaufen mit stripe</b-button>
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";

export default {
  data() {
    this.publishableKey = "pk_test_q7mf9mHoqhhq8O0VcUKx4Z7J00Xs18ul5X";
    return {
      image: "https://i.imgur.com/UvvfGFk.jpg",
      name: "Stripe Checkout",
      description: "ihre Zahlung",
      currency: "EUR",
      loading: false,
      lineItems: [
        {
          price: "price_1LzePwHnRtTpuH2RGkWjWAgw", // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL:
        "http://localhost:8080/login?session_id={CHECKOUT_SESSION_ID}&status={payment_status} ",
      cancelURL: "http://localhost:8080/forgot",
    };
  },
  components: {
    StripeCheckout,
  },
  props: {
    amount: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async checkout() {
      this.loading = true;
      // token - is the token object
      // args - is an object containing the billing and shipping address if enabled
      const result = await this.$refs.checkoutRef.redirectToCheckout();

      console.log("Result :", result);
    },

    tokenCreated(token) {
      console.log(token);
      // handle the token
      // send it to your server
    },
    done() {
      // token - is the token object
      // args - is an object containing the billing and shipping address if enabled
      // do stuff...
      console.log("done...");
    },

    opened() {
      console.log("opened");
    },
    closed() {
      console.log("closed");
    },
    canceled() {
      console.log("canceled");
    },
  },
};
</script>
