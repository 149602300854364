import axios from "axios";

const trackerAPI = 'https://m.myapp2go.de/services/checksecurity';

const Myapp2goTrackerService = {
    service: {
        writeTracker: async(pBody) => {
            try {
                await axios.post(trackerAPI, pBody, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
            } catch (e) {
                console.log(e)
            }
        }
    }
}

export default Myapp2goTrackerService;