<template>
  <div v-if="$route.name == 'home'">

    <div class="footer"
      style="background-image: linear-gradient( #434654,#343541 ); font-size: 12px; color: white; padding: .6em 15px;">
      <div class="dist_10"></div>
      <h6>AI Vision by SYSTEM3-IT Consulting (c)</h6>

      <p>Erdinger Str. 59e</p>
      <p>85459 Berglern</p>
      <p>Telefon: (+49) 8762 426530</p>
      <p>e-mail: support@myapp2go.de</p>
      <p>https://www.myapp2go.de</p>
    </div>

  </div>

</template>

<script>

export default {
  data() {
    return {

    }
  },

  name: 'footer',
  components: {

  },

  methods: {

  }
}

</script>
<style>
.footer {
  margin-bottom: 0rem;
  line-height: 15px;
  text-align: center;
}
</style>