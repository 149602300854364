<template>

    <div>

        <div v-if="spinnnerStatus">

          <div class="dist_150"></div>

                <h4 class="middle">checking the magic ...</h4>

                <div class="dist_50"></div>

                <div class="center">

                    <div class="spinner-3">          
                </div>

                </div>
        </div>

        <div class="dist_150"></div>

        <div v-if="checkStatus" class="center">

            <img src="@/assets/green-check-small.png"/>

        </div>

        <div class="dist_150"></div>

        <div v-if="xStatus" class="center-x">

            <img src="@/assets/red-x.png"/>

            <div class="dist_50"></div>

            <b-button class="btn btn-block" variant="outline-info" v-on:click="gotoLogin()">
            <b-icon icon="person-fill"></b-icon> Login
          </b-button>

        </div>

    </div>

</template>
  
<script>

import { Client, Account } from 'appwrite';


// PROD

const endpoint = 'https://bf2a562.online-server.cloud/v1';
const project = '63446724a6d27e696227';

const client = new Client()
    .setEndpoint(endpoint) // Replace with your endpoint
    .setProject(project); // Replace with your API Key


export default {
    name: "Magic",

    data() {
        return {

            spinnnerStatus: true,
            checkStatus: false,
            xStatus: false

        };
    },
   
    methods: {

        checkMagicLogin: function () {

            this.spinnnerStatus = false;


            console.log("magic-login status checking: "); // Success


            const account = new Account(client);

            const urlParams = new URLSearchParams(window.location.search);

            const userId = urlParams.get('userId');
            const secret = urlParams.get('secret');

            if (userId && secret) {

                const promise = account.updateMagicURLSession(userId, secret);

                promise.then((response) => {

                    console.log("magic-login check: ", response); // Success

                    this.$store.commit("setAuthStatus", true);

                    this.$emit("authenticated", true);

                    this.checkStatus = true;

                    if ('vibrate' in navigator) {
                         window.navigator.vibrate(300);
                    } 

                    this.delay(2000).then(() => this.$router.push({ name: "home" }));

                    //this.spinnnerStatus = false;



                }, (error) => {


                    this.xStatus = true;

                    console.log("magic-login-check-error: ", error); // Failure
                    //alert(error.message);

                    //this.makeToast("danger", error.message, "Login failed");

                    this.makeToast("danger", "Please Log in again here: https://app.myapp2go.de", "Magic URL session expired! ", 5000);
                });

            } else {


                this.xStatus = true;

                console.log("magic-login-check-parameter: no Parameter in URL! "); // Failure

                this.makeToast("danger", "Please Log in again here: https://app.myapp2go.de", "no Parameter in URL! ", 5000);
            }

        },

        delay(time) {
            return new Promise(resolve => setTimeout(resolve, time));
        },

        makeToast(variant = null, pMessage, pText, pTime) {
            this.$bvToast.toast(`ID:  ${pMessage}`, {
                title: pText,
                toaster: "b-toaster-bottom-full",
                autoHideDelay: pTime,
                variant: variant,
                solid: true,
            });
        },

        gotoLogin: function () {

              this.$router.push({ name: "login" });

        },

    },

    created: function () {
        
        this.delay(2000).then(() => this.checkMagicLogin() );

        //this.checkMagicLogin();


    },
};
</script>
  
<style scoped>
.center {

    display: flex;
    justify-content: center;
}


.center-x {

display: grid;
justify-content: center;
}

.middle {

    left: 50%;
    top: 50%;
    text-align: center;

}

.dist_150 {
    margin-bottom: 150px;
}

.dist_50 {
    margin-bottom: 50px;
}


.spinner-3 {
    width: 80px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #40AD48;
    --_m:
        conic-gradient(#0000 10%, #000),
        linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: s3 1s infinite linear;
}

@keyframes s3 {
    to {
        transform: rotate(1turn)
    }
}
</style>